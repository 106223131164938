import {
  getFirestore,
  addDoc,
  collection,
  getDoc,
  doc,
} from "firebase/firestore";

const loadGameState = () => {
  const savedState = localStorage.getItem("conundroGameState");
  return savedState ? JSON.parse(savedState) : false;
};

const saveGameState = (state) => {
  localStorage.setItem("conundroGameState", JSON.stringify(state));
};

// get doc with specific doc id
const getDocFromFirestore = async (docId, db) => {
  try {
    const docRef = doc(db, "prompts", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      // console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

const getLocalDateString = () => {
  const localDate = new Date();
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, "0");
  const day = String(localDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export {
  loadGameState,
  saveGameState,
  getDocFromFirestore,
  getLocalDateString,
};
