import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD64ItYr2T2TT4TD7lJMeSIhIwuKZ1fQPE",
  authDomain: "conundro-2c369.firebaseapp.com",
  projectId: "conundro-2c369",
  storageBucket: "conundro-2c369.appspot.com",
  messagingSenderId: "177291585911",
  appId: "1:177291585911:web:25a8cfa7dfb5cee08e9972",
  measurementId: "G-VDNKCQ35CM",
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
