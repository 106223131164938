import React from "react";
import "./OldGame.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faRandom);

const OldGame = ({ fetchRandomDoc }) => {
  return (
    <div className="play-old">
      <p>
        <span>Finished today's game?</span> No worries- we'll pick a random date
        for you to play one of the previous games!
      </p>
      <button
        onClick={() => {
          fetchRandomDoc();
        }}
      >
        <FontAwesomeIcon icon={faRandom} style={{ color: "white" }} />
      </button>
    </div>
  );
};

export default OldGame;
