import React from "react";
import { useState } from "react";
import "./CookiePolicy.css";

const CookiePolicy = () => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    showBanner && (
      <div className={`cookie-banner`}>
        <div className="cookie-banner-header">
          <h3>Cookie Policy 🍪</h3>
          <button onClick={() => setShowBanner(false)}>X</button>
        </div>
        <p>
          We use browser storage to improve your experience on our site. By
          using our site, you consent to our use of browser storage. To learn
          more, read our{" "}
          <a href="/privacy-policy.html" target="_blank">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="/cookie-policy.html" target="_blank">
            Cookie Policy
          </a>
          .
        </p>
      </div>
    )
  );
};

export default CookiePolicy;
