import React from "react";
import "./Note.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faCircleQuestion);

const Note = () => {
  return (
    <aside className="how-to">
      <h2>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          style={{ marginRight: ".35em" }}
        />
        How do I play?
      </h2>
      <p>
        In this AI word game, you will receive a set of scrambled words
        (carefully chosen by GPT-4 AI) each day, along with a cryptic clue that
        hints at their common theme.
      </p>
      <br></br>
      <p>
        Use the clue to help you unscramble each word. Each word relates to the
        provided hint. Type your guess into the input box provided for each
        scrambled word. You have unlimited guesses.
      </p>
      <br></br>
      <p>
        After you’ve made your guesses, click the "Check Answers" button. You’ll
        receive feedback on whether your guesses are correct.
      </p>
      <br></br>
      <p>A new game will be created each day!</p>
    </aside>
  );
};

export default Note;
