import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPuzzlePiece, faRandom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confetti from "canvas-confetti";
import "./MainApp.css";
import Countdown from "../Countdown";
import "../../firebase";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import {
  getLocalDateString,
  loadGameState,
  saveGameState,
} from "../../helpers";
import Error from "../Error";
import axios from "axios";
import Spinner from "../Spinner";
import OldGame from "../OldGame";

library.add(faPuzzlePiece);
library.add(faRandom);

const ConundrumQuest = () => {
  const [puzzle, setPuzzle] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRandomGame, setIsRandomGame] = useState(false);
  const db = getFirestore();

  // Scramble function to shuffle the characters of the word
  const scrambleWord = (word) => {
    const letters = word.split("");
    for (let i = letters.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [letters[i], letters[j]] = [letters[j], letters[i]];
    }
    return letters.join("");
  };

  useEffect(() => {
    const fetchDocFromDB = async () => {
      const today = getLocalDateString();
      const docRef = doc(db, "prompts", today);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-4",
              messages: [
                {
                  role: "system",
                  content:
                    "You are an assistant that generates word puzzles for a daily game.",
                },
                {
                  role: "user",
                  content: `Create an engaging word puzzle suitable for a daily game. The puzzle should include a list of singular words related to a specific theme, along with an engaging, unique clue that ties them together. Please ensure that the words are not too easy/elementary to solve. The different answers can be of varying length, but all must be one word. Provide the one word solutions, and the clue in JSON format as follows:
  {
    "clue": "An engaging description of the theme.",
    "correctAnswers": [
      "______",
      "______",
      "______",
      "______",
      "______"
    ]
  }`,
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer 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`, //TODO: PROCESS.ENV
                "Content-Type": "application/json",
              },
            }
          );

          const data = JSON.parse(response.data.choices[0].message.content);
          const { clue, correctAnswers } = data;
          const scrambledWords = correctAnswers.map((word) =>
            scrambleWord(word)
          );

          const gameData = {
            scrambledWords,
            correctAnswers,
            clue,
            date: today,
          };

          await setDoc(docRef, gameData);

          // console.log("DOC NOT FOUND. CREATING : ", gameData);

          setPuzzle({
            ...gameData,
            userGuesses: ["", "", "", "", ""],
            feedback: ["", "", "", "", ""],
          });
          setLoading(false);
        } catch (e) {
          setError(
            "Oops! Looks like we've encountered an error. Please try again later."
          );
          console.error("Error creating document: ", e);
          setLoading(false);
        }
      } else {
        // TODO: we should actually check if we have storage here, that way we don't need to call the API again.
        const gameData = docSnap.data();
        // console.log("DOC FOUND: ", gameData);
        setPuzzle({
          ...gameData,
          userGuesses: ["", "", "", "", ""],
          feedback: ["", "", "", "", ""],
        });
        setLoading(false);
      }
    };
    const fetchAndCreatePuzzle = async () => {
      const savedState = loadGameState();
      const currentDateString = getLocalDateString();

      if (savedState) {
        if (savedState.lastUpdated !== currentDateString) {
          fetchDocFromDB();
        } else {
          // console.log("LOADING FROM STATE");
          setLoading(false);
          setPuzzle(savedState);
        }
      } else {
        fetchDocFromDB();
      }
    };

    fetchAndCreatePuzzle();
  }, []);

  // Fetch a random document from the database
  const fetchRandomDoc = async () => {
    setLoading(true);
    setError(null);
    setIsRandomGame(false);

    try {
      const currentDateString = getLocalDateString();
      const querySnapshot = await getDocs(collection(db, "prompts"));
      const allData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (doc) => doc.date !== currentDateString && doc.date !== puzzle.date
        );

      if (allData.length === 0) {
        throw new Error("No documents found.");
      }

      const randomIndex = Math.floor(Math.random() * allData.length);
      const randomPuzzle = {
        ...allData[randomIndex],
        userGuesses: ["", "", "", "", ""],
        feedback: ["", "", "", "", ""],
      };

      setPuzzle(randomPuzzle);
      setIsRandomGame(true);
    } catch (error) {
      setError("Failed to fetch a random game. Please try again later.");
      console.error("Error fetching random game:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedGuesses = [...puzzle.userGuesses];
    updatedGuesses[index] = value.toUpperCase();
    setPuzzle({ ...puzzle, userGuesses: updatedGuesses });
  };

  const checkAnswers = () => {
    const feedback = puzzle?.userGuesses?.map((guess, index) =>
      guess.toLowerCase().trim() === puzzle.correctAnswers[index].toLowerCase()
        ? "Correct"
        : "Incorrect"
    );
    setPuzzle({ ...puzzle, feedback });

    const allCorrect = feedback?.every((f) => f === "Correct");
    if (allCorrect) {
      const newState = {
        ...puzzle,
        feedback,
        allCorrect,
        lastUpdated: getLocalDateString(),
      };
      confetti({
        particleCount: 150,
        spread: 180,
        origin: { y: -0.1 },
        startVelocity: -35,
        colors: ["#FFFFFF", "#b026ff"],
      });
      setPuzzle(newState);
      // if it's a random game, don't save the state
      if (!isRandomGame) {
        saveGameState(newState);
      }
    }
  };

  const allInputsFilled = () => {
    return puzzle.userGuesses?.every((guess) => guess.trim() !== "");
  };

  return (
    <>
      <OldGame fetchRandomDoc={fetchRandomDoc} />
      <div className="conundrum-quest">
        <h1>Conundro</h1>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Error error={error} />
        ) : (
          <>
            <Countdown />
            <div className="clue">
              <p>
                <span>
                  <FontAwesomeIcon
                    icon={faPuzzlePiece}
                    style={{ color: "#b026ff" }}
                  />{" "}
                  TODAY'S CLUE:
                </span>{" "}
                {puzzle.clue}
              </p>
            </div>
            {puzzle?.scrambledWords?.map((word, index) => (
              <div key={index} className={`word-optn`}>
                <label>
                  <span>{word?.toUpperCase()}:</span>
                  <div
                    className={`input-ctr ${
                      puzzle.feedback[index] === "Correct"
                        ? "correct"
                        : puzzle.feedback[index] === "Incorrect"
                        ? "incorrect"
                        : ""
                    }`}
                  >
                    <input
                      type="text"
                      value={puzzle.userGuesses[index]}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      disabled={puzzle.allCorrect}
                    />
                  </div>
                </label>
              </div>
            ))}
            <button
              onClick={checkAnswers}
              className="check-btn"
              disabled={!allInputsFilled() || puzzle.allCorrect}
            >
              {puzzle.allCorrect ? "ALL DONE FOR TODAY! 🎉" : "CHECK ANSWERS"}
            </button>
            {/* Provide option to play old game as well */}
            {puzzle.allCorrect && (
              <button
                onClick={fetchRandomDoc}
                className="old-game-btn"
                disabled={loading}
              >
                PLAY PREVIOUS GAME{" "}
                <FontAwesomeIcon icon={faRandom} style={{ color: "#921ad8" }} />
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ConundrumQuest;
