import React, { useState, useEffect } from "react";
import "./Countdown.css";

const Countdown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0); // Set the time to midnight of the next day
    const difference = nextMidnight - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      {timeLeft.hours > 0 || timeLeft.minutes > 0 ? (
        <span>
          <span className="bold">
            {timeLeft.hours} hours and {timeLeft.minutes + 1} minutes
          </span>{" "}
          until the next challenge
        </span>
      ) : (
        <span>
          <span className="bold">REFRESH</span> to load today's game!
        </span>
      )}
    </div>
  );
};

export default Countdown;
