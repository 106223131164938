import "./App.css";
import CookiePolicy from "./components/CookiePolicy";
import FAQ from "./components/FAQ";
import Layout from "./components/Layout";
import ConundrumQuest from "./components/MainApp";
import Note from "./components/Note";

function App() {
  return (
    <div className="App">
      <Layout>
        <ConundrumQuest />
        <Note />
        <FAQ />
        <CookiePolicy />
        <div className="legal-ctr">
          {/* Privacy Policy */}
          <div className="privacy-policy">
            <a href="/privacy-policy.html" target="_blank">
              Privacy Policy
            </a>
          </div>
          {/* Cookie Policy */}
          <div className="cookie-policy">
            <a href="/cookie-policy.html" target="_blank">
              Cookie Policy
            </a>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default App;
