import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FAQ.css";

library.add(faClipboardQuestion);

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>
        <FontAwesomeIcon
          icon="fa-solid fa-clipboard-question"
          style={{ marginRight: ".35em" }}
        />
        F.A.Q.
      </h2>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(0)}>
          How are the puzzles generated?
        </button>
        <div className={`faq-answer ${activeIndex === 0 ? "active" : ""}`}>
          <p>
            We use the OpenAI GPT-4 AI model to generate the puzzles daily. The
            model is trained on a diverse range of text data and is able to
            generate puzzles that are challenging and fun to solve.
          </p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(1)}>
          How is my progress saved?
        </button>
        <div className={`faq-answer ${activeIndex === 1 ? "active" : ""}`}>
          <p>
            Your progress for the day’s puzzle is saved in your browser's local
            storage. This means you can leave the page and come back later
            without losing your progress. However, your progress for random
            games is not saved once you navigate away from the game.
          </p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(2)}>
          Can I play previous puzzles or random games?
        </button>
        <div className={`faq-answer ${activeIndex === 2 ? "active" : ""}`}>
          <p>
            Yes, you can play random games by clicking the button towards the
            top of the page. This will load a puzzle from a previous day that
            you haven't solved yet in the current session. Each random game is
            unique, ensuring you don’t see the same puzzle twice in one session.
          </p>
        </div>
      </div>
      <div className="faq-item">
        <button className="faq-question" onClick={() => toggleFAQ(3)}>
          Any new features coming soon?
        </button>
        <div className={`faq-answer ${activeIndex === 3 ? "active" : ""}`}>
          <p>
            Yes! We are working on adding a feature that will allow for us to
            track the current high score for the day's puzzle, providing a bit
            of friendly competition for those who enjoy a challenge. We are also
            working on a feature that will allow you to share your progress on
            social media. Coming in June 2024!
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
